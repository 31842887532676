import { customFontsConstants } from "../constants/customFonts.constants";
import { fonts as FONTS } from "../constants/FontPicker"

const initialState = {
	title: FONTS[1],
	subtitle: FONTS[1],
	texts: FONTS[0]
}

export const customFontsReducer = (state = initialState, action) => {
	const { type, field, font, fonts } = action;
	switch (type) {
		case customFontsConstants.SET_FONT:
			return {
				...state,
				[field]: font
			};
		case customFontsConstants.UPDATE_FONT_REQUEST:
			return { ...state }
		case customFontsConstants.UPDATE_FONT_SUCCESS:
			return { ...state }
		case customFontsConstants.UPDATE_FONT_FAILURE:
			return { ...state }
		case customFontsConstants.SET_ALL_FONT:
			const newFonts = {};
			for (const key in fonts) {
				if (fonts.hasOwnProperty(key)) {
					const fontFound = FONTS.find(font => fonts[key] === font.key)
					if (fontFound) {
						newFonts[key] = (fontFound)
					}
				}
			}
			return {
				...state,
				...newFonts
			}
		default:
			return state
	}
}