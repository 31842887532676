import { userConstants } from './../constants/user.constants';
import { NODE_ENV } from '../constants/env';

let user = typeof window !== 'undefined' && JSON.parse(localStorage.getItem('user'));
const initialState = user ? { user } : {};

export function authentication(state = initialState, action) {
	// eslint-disable-next-line no-console
	if (NODE_ENV === "development") console.log("authentication", state, action);

	switch (action.type) {
		case userConstants.LOGIN_REQUEST:
			return {
				...state,
				loggingIn: true,
				user: action.user
			};
		case userConstants.LOGIN_SUCCESS:
			return { ...state, user: action.user, loggingIn: false };
		case userConstants.LOGIN_FAILURE:
			return { ...state, error: action.error, loggingIn: false };
		case userConstants.LOGOUT:
			return {};
		case userConstants.UPDATE_USER:
			return {
				user: action.user
			}
		default:
			return state
	}
}