export const EMPTY_FIELD = 'Este campo no puede estar vacio';
export const SELECT_ONE_OPTION = 'Selecciona una opción';
export const ERROR_ON_REQUEST = 'La solicitud ha fallado';
export const SERVER_SIDE_ERROR = 'No hemos podido procesar tu solicitud, por favor intenta mas tarde.';
export const INVALID_EMAIL_FORMAT = 'Correo electrónico invalido';
export const INVALID_PHONE_NUMBER_FORMAT = 'Formato de teléfono invalido';
export const INVALID_NIT_FORMAT = 'Formato de nit invalido';
export const PHONE_NUMBER_TOO_LONG = 'El numero de teléfono es muy largo';
export const LIMIT_SALARY_VALUE = 'Limite maximo de salario $ 999.999.999';
export const PASSWORD_CONFIRMATION_MISMATCH = 'Las contraseñas no coinciden';
export const FAIL_LOADING_COMPONENT = 'Algo salio mal';
export const PASSWORD_MIN_LENGTH = 'La contraseña debe poseer mínimo 8 caracteres';
export const INVALID_TOKEN = 'Token invalido, vuelve a solicitar el restablecimiento de la contraseña';
export const ERROR_FETCH_ACTIVATION_CREATION = 'No se ha podido realizar la solicitud, si ya has activado tu cuenta, por favor inicia sesión.';
export const DATES_INVALID_FOR_SCHEDULE = "hora final no puede ser a menor e la inicial";
export const INVALID_SCHEDULE = "Horario de atención invalido, las fechas no pueden ser iguales";

//IMAGE
export const MIN_WIDTH_INVALID = "La imagen no cumple el ancho mínimo";
export const MIN_HEIGHT_INVALID = "La imagen no cumple el alto mínimo";
export const INVALID_IMAGE_EXT = 'Formato invalido, formatos validos: jpg,gif,png.';
export const INVALID_IMAGE = 'No se ha podido cargar la imagen';
export const IMAGE_NOT_UPDATED = 'No se ha podido actualizar el imagen';

//FORM
export const REQUERID_FIELD = 'Este campo es requerido';
export const INVALID_CITY = 'La ciudad debe estar entre las opciones';
export const CITY_NEEDED = 'Debes escoger una ciudad';
// export const INVALID_URL_FORMAT = 'formato invalido, usa http://example.com/path ó https://example.com/path'; #WITH HTTPS
export const INVALID_URL_FORMAT = 'formato invalido, usa example.com/path ó www.example.com/path';
export const INVALID_NUMBER_RANGE = range => `Valor invalido, ingresa un rango entre (${range.min} a ${range.max})`
