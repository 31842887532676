export const fonts = [
	{
		name: "Roboto",
		link: "https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap",
		key: "roboto"
	},
	{
		name: "Poppins",
		link: "https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap",
		key: "poppins"
	},
	{
		name: "Quicksand",
		link: "https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100&family=Quicksand:wght@300;500;700&display=swap",
		key: "quicksand"
	},
	{
		name: "Open Sans",
		link: "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;600;700&display=swap",
		key: "open_sans"
	},
	{
		name: "Noto Sans JP",
		link: "https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;500;700&display=swap",
		key: "noto_sans_jp"
	},
	{
		name: "Lato",
		link: "https://fonts.googleapis.com/css2?family=Lato:wght@300;700;900&display=swap",
		key: "lato"
	},
	{
		name: "Montserrat",
		link: "https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap",
		key: "montserrat"
	},
	{
		name: "Source Sans Pro",
		link: "https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap",
		key: "source_sans_pro"
	},
	{
		name: "Oswald",
		link: "https://fonts.googleapis.com/css2?family=Oswald:wght@300;500;700&display=swap",
		key: "oswald"
	},
	{
		name: "Sansita Swashed",
		link: "https://fonts.googleapis.com/css2?family=Sansita+Swashed:wght@300;500;700&display=swap",
		key: "sansita_swashed"
	},
	{
		name: "Comfortaa",
		link: "https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;500;700&display=swap",
		key: "comfortaa"
	},
	{
		name: "Baloo 2",
		link: "https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;700&display=swap",
		key: "baloo_2"
	}
]