import { authHeader } from '../helpers/auth-header';
import {
	urlLogin,
	urlResetPassword,
	urlNewPassword,
	urlGetUserFromCentralToken,
	urlLoginGoogle,
	urlLoginMicrosoft,
	urlMicrosoftConnectAccount,
	urlGoogleConnectAccount,
	urlRegister,
	urlAcceptCorporationInvitation,
	urlUpdateProfile
} from '../constants/urls';
import { handleResponse, deleteCookie } from './../helpers/common-functions';
import { DOMAIN_COOKIE_CENTRAL_AUTH } from '../constants/env';

export const login = (email, password) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify({ email, password })
	};

	return fetch(urlLogin, requestOptions)
		.then(handleResponse)
		.then(user => {
			// store user details and jwt token in local storage to keep user logged in between page refreshes
			localStorage.setItem('user', JSON.stringify(user));

			return user;
		});
}

export const googleLogin = (data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data)
	};

	return fetch(urlLoginGoogle, requestOptions)
		.then(handleResponse)
		.then(user => {
			// store user details and jwt token in local storage to keep user logged in between page refreshes
			typeof window !== 'undefined' && localStorage.setItem('user', JSON.stringify(user));

			return user;
		});
}

export const googleConnectAccount = (data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data)
	};

	return fetch(urlGoogleConnectAccount, requestOptions).then(handleResponse);
}


export const microsoftLogin = (data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data)
	};

	return fetch(urlLoginMicrosoft, requestOptions).then(handleResponse);
}

export const microsoftConnectAccount = (data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data)
	};

	return fetch(urlMicrosoftConnectAccount, requestOptions).then(handleResponse);
}

const logout = () => {
	deleteCookie('creatorApp', null, DOMAIN_COOKIE_CENTRAL_AUTH);
	deleteCookie('creatorApp', '/', DOMAIN_COOKIE_CENTRAL_AUTH);
	deleteCookie('centralAuth', null, DOMAIN_COOKIE_CENTRAL_AUTH);
	deleteCookie('centralAuth', '/', DOMAIN_COOKIE_CENTRAL_AUTH);
	deleteCookie('role');
	deleteCookie('current_activation');
	typeof window !== 'undefined' && localStorage.removeItem('user');
	window.location.reload();
}

const resetPassword = (email) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify({ email })
	}
	return fetch(urlResetPassword, requestOptions).then(handleResponse);
}

export const updatePassword = (resetToken, newPass, newPassConfirm) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify({
			reset_password_token: resetToken,
			password: newPass,
			password_confirmation: newPassConfirm
		})
	}
	return fetch(urlNewPassword, requestOptions).then(handleResponse);
}

export const getUserFromCentralToken = (creatorApp, token, origin) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify({
			creator_app: creatorApp,
			jwt_token: token,
			origin
		})
	}
	return fetch(urlGetUserFromCentralToken, requestOptions).then(handleResponse);
}

const register = (user) => {
	const { email, password, passwordConfirmation: password_confirmation, gRecaptchaResponse: g_recaptcha_response, ...rest } = user;
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify({ email, password, password_confirmation, g_recaptcha_response, ...rest })
	};

	return fetch(urlRegister, requestOptions).then(handleResponse);
}

export const acceptCorporationInvitation = token => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify({ token })
	}
	return fetch(urlAcceptCorporationInvitation, requestOptions).then(handleResponse);
}

export const patchUpdateProfile = (data) => {
	const requestOptions = {
		method: 'PATCH',
		headers: authHeader(),
		body: JSON.stringify(data)
	}
	return fetch(urlUpdateProfile, requestOptions).then(handleResponse);
}

export const userService = {
	login,
	logout,
	register,
	resetPassword,
	updatePassword,
	googleLogin,
	microsoftLogin,
	acceptCorporationInvitation
};