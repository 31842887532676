import { alertConstants } from './../constants/alert.constants';

export function alert(state = {}, action) {
	switch (action.type) {
		case alertConstants.SUCCESS:
			return {
				type: action.type,
				title: action.title || " Exito",
				message: action.message
			};
		case alertConstants.ERROR:
			return {
				type: action.type,
				title: action.title || " Error",
				message: action.message
			};
		case alertConstants.INFO:
				return {
					type: action.type,
					title: action.title || " Información",
					message: action.message
				};
		case alertConstants.CLEAR:
			return {};
		default:
			return state
	}
}