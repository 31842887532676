import { applicationConstants } from "../constants/application.constants";
import { createCorporateRoute } from "../constants/routes";
import { isEmptyArray } from "../helpers/common-functions";
import { history } from "../helpers/history";
import { getCorporationsByCurrentUser, getShowApprovalConditionsService } from "../services/Corporation";
import { updateUser } from "./user.actions";

export const setCurrentCorporation = currentCorporation => ({ type: applicationConstants.SET_CURRENT_CORPORATION, currentCorporation });

export const setFirstCorporationAsCurrent = () => {

	return (dispatch, getState) => {
		const { corporations } = getState().applicationReducer;
		if (corporations && corporations.length > 0) {
			const firstCorporation = corporations[0];
			dispatch(setCurrentCorporation(firstCorporation));
		}
	}
};

export const getCorporationsByUser = () => {

	const getCorporationsRequest = () => ({ type: applicationConstants.GET_CORPORATIONS_REQUEST });
	const getCorporationsSuccess = corporations => ({ type: applicationConstants.GET_CORPORATIONS_SUCCESS, corporations });
	const getCorporationsFailure = error => ({ type: applicationConstants.GET_CORPORATIONS_FAILURE, error });

	return (dispatch, getState) => {
		const { user } = getState().authentication;
		dispatch(getCorporationsRequest());
		getCorporationsByCurrentUser().then(corporations => {
			if (corporations && !user.firstCorporation) {
				dispatch(updateUser({ ...user, firstCorporation: corporations[0] }));
			}
			if (!isEmptyArray(corporations)) {
				dispatch(setCurrentCorporation(corporations[0]));
			} else {
				history.push(createCorporateRoute)
			}
			dispatch(getCorporationsSuccess(corporations));
		}).catch(error => {
			dispatch(getCorporationsFailure(error));
		});
	}
}

export const addCorporation = corporation => ({ type: applicationConstants.ADD_CORPORATION, corporation });

export const setCurrentPath = path => ({ type: applicationConstants.SET_CURRENT_PATH, path });

export const setViewPort = viewPort => ({ type: applicationConstants.SET_VIEW_PORT, viewPort });

export const setLastAutosave = (sendMessage = true) => {
	if (sendMessage) {
		window.parent.postMessage(applicationConstants.SET_LAST_AUTOSAVE)
	}
	return { type: applicationConstants.SET_LAST_AUTOSAVE };
}

export const setAutoSaving = (sendMessage = true) => {
	if (sendMessage) {
		window.parent.postMessage(applicationConstants.SET_AUTO_SAVING);
	}
	return { type: applicationConstants.SET_AUTO_SAVING };
}

export const getShowApprovalConditions = () => {

	const getShowApprovalRequest = () => ({ type: applicationConstants.GET_SHOW_APPROVAL_REQUEST });
	const getShowApprovalSuccess = approvalConditions => ({ type: applicationConstants.GET_SHOW_APPROVAL_SUCCESS, approvalConditions });
	const getShowApprovalFailure = error => ({ type: applicationConstants.GET_SHOW_APPROVAL_FAIL, error });

	return (dispatch, getState) => {
		const { currentCorporation } = getState().applicationReducer;
		dispatch(getShowApprovalRequest());
		getShowApprovalConditionsService(currentCorporation.slug).then(data => {
			dispatch(getShowApprovalSuccess(data));
		}).catch(error => {
			console.error(error)
			dispatch(getShowApprovalFailure(error));
		})
	}
}