export const socialLoginConstants = {
	GOOGLE_CONNECT_REQUEST: "GOOGLE_CONNECT_REQUEST",
	GOOGLE_CONNECT_SUCCESS: "GOOGLE_CONNECT_SUCCESS",
	GOOGLE_CONNECT_FAILURE: "GOOGLE_CONNECT_FAILURE",
	GOOGLE_LOGIN_REQUEST: "GOOGLE_LOGIN_REQUEST",
	GOOGLE_LOGIN_SUCCESS: "GOOGLE_LOGIN_SUCCESS",
	GOOGLE_LOGIN_FAILURE: "GOOGLE_LOGIN_FAILURE",
	MICROSOFT_CONNECT_REQUEST: "MICROSOFT_CONNECT_REQUEST",
	MICROSOFT_CONNECT_SUCCESS: "MICROSOFT_CONNECT_SUCCESS",
	MICROSOFT_CONNECT_FAILURE: "MICROSOFT_CONNECT_FAILURE",
	MICROSOFT_LOGIN_REQUEST: "MICROSOFT_LOGIN_REQUEST",
	MICROSOFT_LOGIN_SUCCESS: "MICROSOFT_LOGIN_SUCCESS",
	MICROSOFT_LOGIN_FAILURE: "MICROSOFT_LOGIN_FAILURE",
}