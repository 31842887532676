import {
	LOAD_IMAGES,
	SELECT_IMAGE,
	ADD_IMAGE,
	REQUEST_UPLOAD_IMAGE,
	SUCCESS_UPLOAD_IMAGE,
	FAILURE_UPLOAD_IMAGE,
	RESET_DATA_IMAGE
} from "../constants/cropper.constants";

const defaultState = {
	index: 0,
	files: '',
	imageSrc: [],
	cropperResult: '',
	selectedImages: {},
	images: {},
	error: ''
}

export function cropperReducer(state = defaultState, action) {
	switch (action.type) {
		case LOAD_IMAGES:
			return Object.assign({}, state, { files: action.files, index: 0 });
		case SELECT_IMAGE:
			return Object.assign({}, state, { selectedImages: { ...state.selectedImages, [action.imageName]: action.selectImage }, index: action.index });
		case ADD_IMAGE:
			return Object.assign({}, state, { imageSrc: [...state.imageSrc, action.imageSrc], cropperResult: action.imageSrc, selectedImage: '' });
		case RESET_DATA_IMAGE:
			return {...state, files: '', selectedImages: {}, error: ''};
		case REQUEST_UPLOAD_IMAGE:
			return Object.assign({}, state, { requesting: true, error: '' });
		case SUCCESS_UPLOAD_IMAGE:
			return Object.assign({}, state, { requesting: false, index: 0, files: '', selectedImages: { ...state.selectedImages, [action.imageName]: '' }, imageSrc: [], images: { ...state.images, [action.imageName]: action.newImage } });
		case FAILURE_UPLOAD_IMAGE:
			return Object.assign({}, state, { requesting: false, error: action.error });
		default:
			return state;
	}
}