export const applicationConstants = {
	SET_CURRENT_CORPORATION: "SET_CURRENT_CORPORATION",
	GET_CORPORATIONS_BY_USER: "GET_CORPORATIONS_BY_USER",
	GET_CORPORATIONS_REQUEST: "GET_CORPORATIONS_REQUEST",
	GET_CORPORATIONS_SUCCESS: "GET_CORPORATIONS_SUCCESS",
	GET_CORPORATIONS_FAILURE: "GET_CORPORATIONS_FAILURE",
	ADD_CORPORATION: "ADD_CORPORATION",
	SET_CORPORATION_TO_CREATE: "SET_CORPORATION_TO_CREATE",
	SET_CURRENT_PATH: "SET_CURRENT_PATH",
	GET_CORPORATION_ROL_REQUEST: "GET_CORPORATION_ROL_REQUEST",
	GET_CORPORATION_ROL_SUCCESS: "GET_CORPORATION_ROL_SUCCESS",
	GET_CORPORATION_ROL_FAILURE: "GET_CORPORATION_ROL_FAILURE",
	SET_VIEW_PORT: "SET_VIEW_PORT",
	SET_LAST_AUTOSAVE: "SET_LAST_AUTOSAVE",
	SET_AUTO_SAVING: "SET_AUTO_SAVING",
	GET_SHOW_APPROVAL_REQUEST: "GET_SHOW_APPROVAL_REQUEST",
	GET_SHOW_APPROVAL_SUCCESS: "GET_SHOW_APPROVAL_SUCCESS",
	GET_SHOW_APPROVAL_FAIL: "GET_SHOW_APPROVAL_FAIL",
}