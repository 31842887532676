import { userConstants } from './../constants/user.constants';

export function registration(state = {}, action) {
	switch (action.type) {
		case userConstants.REGISTER_REQUEST:
			return { registering: true, error: null };
		case userConstants.REGISTER_SUCCESS:
			return { registered: true, userRegistered: action.user };
		case userConstants.REGISTER_FAILURE:
			return { error: action.error};
		default:
			return state
	}
}