import { PARTNER_TOKEN } from "../constants/env";

export const authHeader = (contentType='application/json') => {
	let user = typeof window !== 'undefined' && JSON.parse(localStorage.getItem('user'));
	const headersData = {
		'Content-Type': contentType,
		'App-Token': PARTNER_TOKEN
	}
    if (user && user.token) {
		headersData["Authorization"] = `Bearer ${user.token}`;
        return new Headers(headersData);
    } else {
        return new Headers(headersData);
    }
}