import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from "react-router-dom";
import { createCorporateRoute, welcomeLandingRoute } from './constants/routes';
import { adminRouter } from './constants/router.admin';
import { connect } from 'react-redux';
import { setCurrentPath } from './actions/application.actions';
import Loading from './components/Utils/Loading';
import { authRouter } from './constants/router.auth';

const NotFoundContainer = lazy(() => import('./containers/NotFoundContainer'));

const currentUser = typeof window !== 'undefined' && JSON.parse(localStorage.getItem('user'));

const getCurrentCorporationSlug = (user, currentCorporation) => {
	if (!currentCorporation) {
		return user && user.firstCorporation && user.firstCorporation.slug;
	}
	return currentCorporation.slug
}

const Router = ({ setCurrentPath, currentCorporation }) => {
	return (
		<Suspense fallback={<Loading />}>
			<Switch>
				<Route exact path="/">
					<Redirect to={(getCurrentCorporationSlug(currentUser, currentCorporation) ?
						welcomeLandingRoute(getCurrentCorporationSlug(currentUser, currentCorporation)) : createCorporateRoute)} />
				</Route>
				{
					authRouter.map(({ path, component: Component, routeComponent: Route }) => (
						<Route key={path} exact path={path} component={Component} />
					))
				}
				{
					adminRouter.map(({ path, component: Component, roles, routeComponent: Route, exact }) =>
						<Route key={path} exact={exact} path={path} roles={roles} component={({ match: { params } }) => {
							setCurrentPath(path);
							return <Component {...params} />
						}} />
					)
				}
				<Route path="*" component={NotFoundContainer} />
			</Switch>
		</Suspense>
	);
};

Router.propTypes = {
	setCurrentPath: PropTypes.func.isRequired,
	currentCorporation: PropTypes.object,
};

const mapStateToProps = ({ applicationReducer: { currentCorporation } }) => (
	{ currentCorporation }
)

const actionCreators = {
	setCurrentPath
}

export default connect(mapStateToProps, actionCreators)(Router);