import { NODE_ENV, URL_PROD, FRONTEND_URL_PROD, URL_PROD_ADMIN, URL_PROD_VECINDARIO } from "./env";

let urlBaseAdmin = NODE_ENV === "development" ? 'http://localhost:3001/api_content_site/v1/admin/' : URL_PROD_ADMIN;
let urlBase = NODE_ENV === "development" ? 'http://localhost:3001/api_content_site/v1/landing_builder/' : URL_PROD;
let urlBaseVecindario = NODE_ENV === "development" ? 'http://localhost:3001/api_content_site/v1/' : URL_PROD_VECINDARIO;
let urlBaseFront = NODE_ENV === "development" ? 'http://localhost:3002/' : FRONTEND_URL_PROD;

// VECINDARIO
export const urlEnterpriseProjects = `${urlBaseVecindario}index/proyectos-enterprise`;

// AUTH
export const urlLogin = `${urlBaseAdmin}auth/ingresar`;
export const urlResetPassword = `${urlBaseAdmin}auth/recuperar-constrasena`;
export const urlNewPassword = `${urlBaseAdmin}auth/reiniciar-constrasena`;
export const urlGetUserFromCentralToken = `${urlBaseAdmin}auth/central-login`;
export const urlLoginGoogle = `${urlBaseAdmin}auth/ingresar/google`;
export const urlGoogleConnectAccount = `${urlBaseAdmin}auth/conectar/google`;
export const urlLoginMicrosoft = `${urlBaseAdmin}auth/ingresar/microsoft`;
export const urlMicrosoftConnectAccount = `${urlBaseAdmin}auth/conectar/microsoft`;
export const urlRegister = `${urlBaseVecindario}auth/registrarse`;
export const urlVecindarioViewLikeUser = userId => `${urlBaseFront}perfil/diagnostico-financiero/resumen?onBehalfOfId=${userId}`;

//CITY
export const urlGetCityList = `${urlBaseVecindario}index/lista-ciudades`;
export const urlGetNeighborhoodsByCity = cityID => `${urlBaseVecindario}index/${cityID}/barrios`;

// CORPORATIONS
export const urlGetCorporations = `${urlBase}corporativos`;
export const urlGetOneCorporation = (slug = ':slug') => `${urlBase}corporativos/${slug}`;
export const urlEditCorporation = (slug = ':slug') => `${urlBase}corporativos/${slug}/editar`;
export const urlSubmitForApproval = (slug = ':slug') => `${urlBase}corporativos/${slug}/enviar-para-aprobacion`;
export const urlGetShowApprovalConditions = (slug = ':slug') => `${urlBase}corporativos/${slug}/condiciones-mostrar-aprobacion`;
export const urlSubmitPublishSite = (slug = ':slug') => `${urlBase}corporativos/${slug}/enviar-para-publicacion`;


//LANDING_BUILDER
export const urlUpdateText = (slug = ":slug") => `${urlBase}corporativos/${slug}/actualizar-texto`;
export const urlUpdateLink = (slug = ":slug") => `${urlBase}corporativos/${slug}/actualizar-link`;
export const urlUpdateImage = (slug = ":slug") => `${urlBase}corporativos/${slug}/actualizar-imagen`;
export const urlUpdateFont = (slug = ":slug") => `${urlBase}corporativos/${slug}/actualizar-fuente`;
export const urlUpdateColor = (slug = ":slug") => `${urlBase}corporativos/${slug}/actualizar-color`;
export const urlGetLandingContent = (slug = ":slug") => `${urlBase}corporativos/${slug}/contenido`;
export const urlGetLandingImages = (slug = ":slug") => `${urlBase}corporativos/${slug}/contenido/imagenes`;
export const urlPublishLanding = (slug = ":slug") => `${urlBase}corporativos/${slug}/publicar`;

//EMPLOYEES
export const urlLoadEmployees = (slug = ":slug") => `${urlBase}corporativos/${slug}/empleados/cargar-archivo-empleados`;
export const urlEmployees = (slug = ":slug") => `${urlBase}corporativos/${slug}/empleados`;
export const urlEmployeesMetrics = (slug = ":slug") => `${urlBase}corporativos/${slug}/empleados/metricas`;
export const urlOneEmployee = (slug = ":slug", identification = ':identification') => `${urlBase}corporativos/${slug}/empleados/${identification}`;
export const urlEditEmployee = (slug = ":slug", identification = ':identification') => `${urlBase}corporativos/${slug}/empleados/${identification}/editar`;
export const urlDownloadEmployees = (slug = ":slug") => `${urlBase}corporativos/${slug}/empleados/empleados-actuales`;

// USERS
export const urlCreateUser = (slug = ":slug") => `${urlBase}usuarios/asociar-corporativo/corporativos/${slug}`;
export const urlDestroyUser = (slug = ":slug", userId = ":userId") => `${urlBase}usuarios/${userId}/eliminar/corporativos/${slug}`;
export const urlUpdateUser = (slug = ":slug", userId = ":userId") => `${urlBase}usuarios/${userId}/corporativos/${slug}`;
export const urlGetCorporationUsers = (slug = ":slug") => `${urlBase}usuarios/corporativos/${slug}`;
export const urlAcceptCorporationInvitation = `${urlBase}usuarios/aceptar-invitacion-corporativo`;
export const urlUpdateProfile = `${urlBaseAdmin}me`;