import React from 'react';
import './../styles/onlyDesktop.scss';

const OnlyDesktop = () => {
	return (
		<div className="container d-flex align-items-center justify-content-center only-desktop-ctn position-relative">
			<a href="https://empresas.vecindario.com" className={`go-back-ctn d-flex align-items-center`} >
				<i className="fas fa-angle-left d-flex align-items-center justify-content-center"></i>
				<span>
					Regresar
				</span>
			</a>
			<div className="box d-flex flex-column align-items-center py-5 px-1">
				<img src="https://simulator-by-vecindario.s3-us-west-2.amazonaws.com/static-content/commons/recomendacion-escritorio.jpg" alt="" className="main-img mb-2"/>
				<h1 className="mt-4">Te recomendamos utilizar la plataforma de convenio corporativo desde el pc</h1>
				<p className="mt-2">Para tener una mejor experiencia</p>
				<img src="https://viewinmobiliario2.s3-sa-east-1.amazonaws.com/static_assets/vecindario-logo.svg" alt="" className="footer-img mt-auto" />
			</div>
		</div>
	);
};

export default OnlyDesktop;