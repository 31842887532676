import { importResource } from './helpers/common-functions';

importResource({
	style: `https://fonts.googleapis.com/css?family=Poppins:300,300i,500,500i,700,700i|Quicksand:300,500,700|Roboto:300,400,500,600,700,800&display=swap`,
	id: "google-fonts",
	async: true,
});

importResource({
	style: `https://viewinmobiliario2.s3-sa-east-1.amazonaws.com/static_assets/utils/all.min.gz.css`,
	id: "fontawesome",
	async: true,
});