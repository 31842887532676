import { userConstants } from '../constants/user.constants';

const initialState = {
	resetPasswordSuccess: false,
	requesting: false,
	error: ''
}

export function resetingPassword(state= initialState, action) {
	switch(action.type){
		case userConstants.RESET_PASSWORD_REQUEST:
			return {
				...state,
				requesting: true,
				error: '',
				resetPasswordSuccess: false,
			}
		case userConstants.RESET_PASSWORD_SUCCESS:
			return {
				...state,
				requesting: false,
				resetPasswordSuccess: true,
			}
		case userConstants.RESET_PASSWORD_FAILURE:
			return {
				...state,
				requesting: false,
				error: action.error
			}
		case userConstants.CLEAR_RESET_PASSWORD_FIELDS:
			return {
				...state,
				...initialState
			}
		default:
			return state;
	}
}