import {userConstants} from '../constants/user.constants';

export const updatePasswordReducer = (state = {}, action) => {
	switch(action.type){
		case userConstants.UPDATE_PASSWORD_REQUEST:
			return {updatingPassword: true};

		case userConstants.UPDATE_PASSWORD_SUCCESS:
				return {updatingPassword: false};

		case userConstants.UPDATE_PASSWORD_FAILURE:
				return {updatingPassword: false, error: action.error};

		default:
			return state;
	}
}