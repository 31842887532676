import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { homeRoute, loginRoute } from '../constants/routes';

export const PrivateRoute = ({ component: Component, ...rest }) => {
	return (
	<Route {...rest} render={props => (
		localStorage.getItem('user')
			? <Component {...props} />
			: <Redirect to={{ pathname: loginRoute, state: { from: props.location } }} />
	)} />
)}


export const PrivateRouteByRole = ({ component: Component, roles, ...rest }) => {
	const currentUser = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
	return (
	<Route {...rest} render={props => (
		currentUser && roles.includes(currentUser.role)
			? <Component {...props} />
			: <Redirect to={{ pathname: loginRoute, state: { from: props.location } }} />
	)} />
)}

export const UnauthenticatedRoute = ({ component: C, ...rest }) => {
	const prevPath = typeof window !== 'undefined' && localStorage.getItem("prevPath");
	return (
	<Route {...rest} render={props =>
		typeof window !== 'undefined' && !localStorage.getItem('user')
			? <C {...props}/>
			: <Redirect to={prevPath ? prevPath : homeRoute} />}
	/>
)}