import { formatCurrency } from "../helpers/common-functions";
import { SMMLV } from "./env";

export const emailRegex = /^([a-zA-Z0-9_.-]+)@([\da-zA-Z.-]+)\.([a-zA-Z.]{2,6})$/;
export const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
export const DATA_TALK_WITH_US = "hablemos"
export const ZAPIER_WEBHOOK = "ZAPIER_WEBHOOK";
export const GENERIC_WEBHOOK = "GENERIC_WEBHOOK";

export const DAYS_OF_WEEK = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"];

export const TRANSLATE_ENG_TO_SPA_DAYS_OF_WEEK = { monday: "Lunes", tuesday: "Martes", wednesday: "Miercoles", thursday: "Jueves", friday: "Viernes", saturday: "Sabado", sunday: "Domingo" };

export const WIZARD_CREATION_STEPS = {
	activation: 1,
	completion: 2,
	calendar: 3,
	availability: 4,
}


export const HELP_WELCOME_PROGRESS = [
	{ title: 'Paso 1', description: '¿Qué es Vecindario?' },
	{ title: 'Paso 2', description: 'Mesa de ayuda' },
]

export const HELP_EMPLOYEES_PROGRESS = [
	{ title: 'Paso 1', description: '¿Cómo administrar tus colaboradores?' },
	{ title: 'Paso 2', description: 'Mesa de ayuda' },
]

export const CREATION_ACCOUNT_FIELDS = {
	email: "email",
	password: "password",
	confirmation: "password_confirmation",
}

export const COMPLETE_DATA_FIELDS = {
	name: "name",
	whatsApp: "whats_app",
	companyRole: "company_role",
	companyName: "company_name",
	companyMailing: "company_mailing",
	photo: "photo",
}

export const IMG_LOADER_COMPONENT_ACTION = {
	logo: 0b001,
	banner: 0b010,
	benefit: 0b011,
	testimony: 0b100,
}

export const TOUR_STEP_TARGETS = {
	step_1: 'reports',
	step_2: 'collaborators',
	step_3: 'landing_design',
	step_4: 'team_management',
	step_5: 'publish',

}

export const TOUR_STEPS = Object.freeze([
	// { #TODO enable when reports is completed
	// 	target: TOUR_STEP_TARGETS.step_1,
	// 	title: '¿Qué es reportes?',
	// 	description: "Aquí podrás ver el estado de tu convenio de vivienda, número de cuentas creadas en la plataforma, "+
	// 	"número de diagnósticos positivos entre muchos otros datos de tu interes."
	// },
	{
		target: TOUR_STEP_TARGETS.step_2,
		title: '¿Qué es colaboradores?',
		description: 'Aquí podrás registrar de forma masiva o individual a los colaboradores de tu empresa, que serán los beneficiarios ' +
			'del convenio de vivienda con Vecindario.'
	},
	{
		target: TOUR_STEP_TARGETS.step_3,
		title: '¿Qué es Diseño landing?',
		description: 'Aquí encontrarás las herramientas necesarias para personalizar tu página con tus colores, logos e imagenes de tu empresa.'
	},
	{
		target: TOUR_STEP_TARGETS.step_4,
		title: '¿Qué es Equipo de trabajo?',
		description: 'Aquí podrás agregar a tus compañeros que te ayudaran en el convenio, Podrás otorgar roles como Diseñador, Analista y ' +
			'Administrador.'
	},
	{
		target: TOUR_STEP_TARGETS.step_5,
		title: '¿Qué es Publicar convenio?',
		description: 'Una vez estén listos los colaboradores y tu diseño de landing podrás publicar para que nuestro equipo revise.'
	}
])

export const TOUR_STEPS_DESIGNER = Object.freeze([TOUR_STEPS[1]])

export const TOUR_STEPS_HUMAN_MANAGER = Object.freeze([TOUR_STEPS[0],TOUR_STEPS[1]])

export const DATA_CORPORATION_FIELD_NAMES = Object.freeze({
	name: 'name',
	nit: 'nit',
	phone: 'phone',
	employeeNumber: 'employee_number',
	urlWebsite: 'url_website',
	city: 'city',
	salaryRange: 'salary_range_employee_mean',
	hasHousingBenefit: 'has_housing_benefit',
	legalRepresentative: 'legal_representative',
	consultantMail: 'assessor_ally_agreement',
	leaderConsultantMail: 'leader_assessor_ally_agreement',
})

export const DATA_EMPLOYEE_FIELD_NAMES = Object.freeze({
	identification: 'identification',
	name: 'name',
	lastName: 'last_name',
	email: 'email',
	phone: 'phone',
	isActive: 'is_active',
	linkType: 'link_type',
	salary: 'salary',
})

export const PROFILE_FIELD_NAMES = Object.freeze({
	name: 'name',
	lastName: 'lastname',
	email: 'email',
	phone: 'mobile',
	position: 'corporation_position'
})

export const OPTIONS_EMPLOYEE_NUMBER = Object.freeze([
	{
		key: 'one_to_100',
		display: 'De 1 a 100 colaboradores',
	},
	{
		key: 'more_than_100_less_than_200',
		display: 'De 101 a 200 colaboradores',
	},
	{
		key: 'more_than_200_less_than_300',
		display: 'De 201 a 300 colaboradores',
	},
	{
		key: 'more_than_300_less_than_500',
		display: 'De 301 a 500 colaboradores',
	},
	{
		key: 'more_than_500_less_than_1000',
		display: 'De 501 a 1000 colaboradores',
	},
	{
		key: 'more_than_1000',
		display: 'Más de 1000 colaboradores',
	},
])

export const OPTIONS_SALARY_EMPLOYEE_MEAN = Object.freeze([
	{
		key: 'until_2_smmlv',
		display: `Hasta ${formatCurrency(2 * SMMLV)}`
	},
	{
		key: 'between_2_and_4_smmlv_included',
		display: `Entre ${formatCurrency(2 * SMMLV + 1)} y ${formatCurrency(4 * SMMLV)}`
	},
	{
		key: 'between_4_and_8_smmlv_included',
		display: `Entre ${formatCurrency(4 * SMMLV + 1)} y ${formatCurrency(8 * SMMLV)}`
	},
	{
		key: 'more_than_8_smmlv',
		display: `Más de ${formatCurrency(8 * SMMLV)}`
	},
])

export const OPTIONS_LINK_TYPE = Object.freeze([
	{
		key: 'client',
		display: 'Cliente'
	},
	{
		key: 'partner',
		display: 'Socio'
	},
	{
		key: 'affiliate',
		display: 'Afiliado'
	},
	{
		key: 'collaborator',
		display: 'Colaborador'
	},
	{
		key: 'employee',
		display: 'Empleado'
	},
])

export const LENGTH_MOBILE_PHONE = 10;
export const LENGTH_NIT = 9;