import { combineReducers } from 'redux'
import { applicationReducer } from './application.reducer';
import { authentication } from './authentication.reducer';
import { socialLoginReducer } from './socialLogin.reducer';
import { cropperReducer as cropper } from './cropper.reducer';
import { alert } from './alert.reducer';
import { customFontsReducer } from './customFonts.reducer';
import { customColorsReducer } from './customColors.reducer';
import { resetingPassword } from './resetpassword.reducer';
import { updatePasswordReducer } from './updatePasword.reducer';
import { registration } from './registration.reducer';

export default combineReducers({
	alert,
	applicationReducer,
	authentication,
	cropper,
	customFontsReducer,
	customColorsReducer,
	registration,
	resetingPassword,
	socialLoginReducer,
	updatePasswordReducer,
});