import { socialLoginConstants } from "../constants/socialLogin.constants"

const initialState = {
	googleConnectRequesting: null,
	googleConnected: false,
	googleConnectError: null,
	googleLoginRequesting: null,
	microsoftConnectRequesting: null,
	microsoftConnected: false
}

export const socialLoginReducer = (state = initialState, action) => {
	switch (action.type) {
		case socialLoginConstants.GOOGLE_CONNECT_REQUEST:
			return {
				...state,
				googleConnectRequesting: true,
				googleConnectError: null,
			}
		case socialLoginConstants.GOOGLE_CONNECT_SUCCESS:
			return {
				...state,
				googleConnected: true,
				googleConnectRequesting: false
			}
		case socialLoginConstants.GOOGLE_CONNECT_FAILURE:
			return {
				...state,
				googleConnectError: action.error,
				googleConnectRequesting: false
			}
		case socialLoginConstants.GOOGLE_LOGIN_REQUEST:
			return {
				...state,
				googleLoginRequesting: true,
				googleLoginError: null,
			}
		case socialLoginConstants.GOOGLE_LOGIN_SUCCESS:
			return {
				...state,
				googleLoginRequesting: false,
				googleLoginSuccess: true,
			}
		case socialLoginConstants.GOOGLE_LOGIN_FAILURE:
			return {
				...state,
				googleLoginRequesting: false,
				googleLoginError: action.error,
			}

		case socialLoginConstants.MICROSOFT_CONNECT_REQUEST:
			return {
				...state,
				microsoftConnectRequesting: true,
			}
		case socialLoginConstants.MICROSOFT_CONNECT_SUCCESS:
			return {
				...state,
				microsoftConnectRequesting: false,
				microsoftConnected: true,
				microsoftConnectError: null
			}
		case socialLoginConstants.MICROSOFT_CONNECT_FAILURE:
			return {
				...state,
				microsoftConnectRequesting: false,
				microsoftConnected: false,
				microsoftConnectError: action.error
			}
		case socialLoginConstants.MICROSOFT_LOGIN_REQUEST:
			return {
				...state,
				microsoftLoginRequesting: true,
				microsoftLoginError: null
			}
		case socialLoginConstants.MICROSOFT_LOGIN_SUCCESS:
			return {
				...state,
				microsoftLoginRequesting: false,
				microsoftLoginSucess: true
			}
		case socialLoginConstants.MICROSOFT_LOGIN_FAILURE:
			return {
				...state,
				microsoftLoginRequesting: false,
				microsoftLoginError: action.error
			}
		default:
			return state;
	}

}