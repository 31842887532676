import React from 'react';
import './_loading.scss';

function LoadingPage({onPage}) {
	return (
		<div className={`${onPage ? "overlay-loaging" : 'loading-component-ctn'}`}>
			<div className="loading-ctn">
				<i className="fal fa-spinner-third fa-spin "></i>
				{onPage && <p>
					Cargando...
				</p>}
			</div>
		</div>
	);
}

LoadingPage.defaultProps = {
	onPage: true,
}

export default LoadingPage;