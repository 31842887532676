import React, { useState, useEffect, useCallback } from 'react';

import './toast.scss';
import { alertConstants } from '../../../constants/alert.constants';

const Toast = ({ title, message, type, visibleTime }) => {
	const showOrHide = message ? "show" : "hide";

	const [close, closeToast] = useState('');
	const [timeout, initTimeout] = useState(null);

	useEffect(() => {
		const temp = setTimeout(() => closeToast('closed'), visibleTime || 4000);
		initTimeout(temp);
		return () => temp && clearTimeout(temp);
	}, [visibleTime]);

	const cleanTimeout = useCallback(() => {
		timeout && clearTimeout(timeout);
		initTimeout(null);
	}, [timeout]);

	const callTimeout = () => {
		const temp = setTimeout(() => closeToast('closed'), visibleTime || 4000);
		initTimeout(temp);
	}

	const handleClose = () => {
		closeToast('closed');
	}

	return (
		<div onMouseEnter={cleanTimeout} onMouseLeave={callTimeout} className={`toast custom-toast fade ${type} ${close} ${showOrHide}`}>
			<div className="toast-header">
				{type === alertConstants.SUCCESS && <i className="fal mr-2 fa-check"></i>}
				{type === alertConstants.ERROR && <i className="fal fa-exclamation-circle"></i>}
				{type === alertConstants.INFO && <i className="fal fa-exclamation-triangle"></i>}
				<strong className="mr-auto">{title}</strong>
				<button onClick={handleClose} type="button" className="ml-3 mb-1 close">
					<span aria-hidden="true">×</span>
				</button>
			</div>
			<div className="toast-body">{message}</div>
		</div>
	);
}

export default Toast;