import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { history } from './../helpers/history';
import { homeRoute } from '../constants/routes';
import './../styles/404.scss';

class ErrorBoundary extends PureComponent {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidMount() {
		history.listen(() => {
			this.setState({hasError: false});
		 });
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	render500() {
		return (
			<section className="container not-found-404 text-center mvh-100 align-items-center d-flex justify-content-center">
				<div>
					<img src="https://s3-sa-east-1.amazonaws.com/viewinmobiliario2/static_assets/500/view-imagen-500.png" alt="" className='img-fluid' />
					<h1 className="font-weight-bold">Opssss!!!</h1>
					<p className="">
						Código de error: 500
					</p>
					<Link to={homeRoute} className="btn btn-rounded btn-yellow">
						Regresar
					</Link>
					<p className="simula-by-vecindario">
						Vecindario
					</p>
				</div>
			</section>
		)
	}

	render() {
		if (this.state.hasError) {
			return (this.render500());
		}
		return this.props.children;
	}
}

export default ErrorBoundary;