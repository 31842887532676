import { lazy } from 'react';
import * as routes from './routes';
import { PrivateRoute } from '../helpers/RedirectRoute';

const UserListContainer = lazy(() => import('./../containers/UserListContainer'));
const CorporationContainer = lazy(() => import('./../containers/CorporationContainer'));
const EmployeesContainer = lazy(() => import('./../containers/EmployeesContainer'));
const CorporateHomeContainer = lazy(() => import('./../containers/CorporateHomeContainer'));
const LandingCustomizer = lazy(() => import('./../containers/LandingCustomizer'));
const ProfileContainer = lazy(() => import('./../containers/ProfileContainer'));
const WelcomeLandingContainer = lazy(() => import('./../containers/WelcomeLandingContainer'));

export const adminRouter = [
	{
		path: routes.employeesRoute(),
		component: EmployeesContainer,
		exact: false,
		routeComponent: PrivateRoute
	},
	{
		path: routes.createLandingRoute(),
		exact: true,
		component: CorporateHomeContainer,
		routeComponent: PrivateRoute
	},
	{
		path: routes.userEditProfileRoute,
		exact: true,
		component: ProfileContainer,
		routeComponent: PrivateRoute
	},
	{
		path: routes.editCorporateRoute(),
		exact: true,
		component: CorporationContainer,
		routeComponent: PrivateRoute
	},
	{
		path: routes.createCorporateRoute,
		exact: true,
		component: CorporationContainer,
		routeComponent: PrivateRoute
	},
	{
		path: routes.userListRoute(),
		component: UserListContainer,
		routeComponent: PrivateRoute
	},
	{
		path: routes.welcomeLandingRoute(),
		component: WelcomeLandingContainer,
		routeComponent: PrivateRoute
	},
	{
		path: routes.landingCustomizerRoute(),
		component: LandingCustomizer,
		routeComponent: PrivateRoute
	},
];