export const NODE_ENV = process.env.NODE_ENV;
export const URL_PROD = process.env.REACT_APP_URL_PROD || 'https://api.vecindario.com/api_content_site/v1/landing_builder/';
export const URL_PROD_ADMIN = process.env.REACT_APP_URL_PROD_ADMIN || 'https://api.vecindario.com/api_content_site/v1/admin/';
export const URL_PROD_VECINDARIO = process.env.REACT_APP_URL_PROD_VECINDARIO || 'https://api.vecindario.com/api_content_site/v1/';
export const DOMAIN_COOKIE_CENTRAL_AUTH = process.env.REACT_APP_DOMAIN_COOKIE_CENTRAL_AUTH || '.vecindario.com';
export const FRONTEND_URL_PROD = process.env.REACT_APP_FRONTEND_URL_PROD || 'https://www.vecindario.com/';
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const MICROSOFT_CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID;
export const URL_CLIENT_FRONT = process.env.REACT_APP_URL_CLIENT_FRONT || (window.location &&`${window.location.origin}/proyecto/`) || 'https://admin-nuevo.vecindario.com/proyecto/';
export const CAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY;
export const SMMLV = process.env.REACT_APP_SMMLV || 877803;
export const PARTNER_TOKEN= process.env.REACT_APP_PARTNER_TOKEN;