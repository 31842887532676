import { userConstants } from './../constants/user.constants';
import { userService } from './../services/user.service';
import { history } from './../helpers/history';
import { loginRoute } from '../constants/routes';
import { getFullPathFromPrivateRoute, redirectAfterLogin, setCentralAuthCookies } from './../helpers/common-functions';

export const updateUser = (user) => {
	typeof window !== 'undefined' && localStorage.setItem('user', JSON.stringify(user));
	return {type: userConstants.UPDATE_USER, user};
}

export const login = (email, password, fromRegister=false) => {

	const request = user =>  ({ type: userConstants.LOGIN_REQUEST, user });
	const success = user =>  ({ type: userConstants.LOGIN_SUCCESS, user });
	const failure = error =>  ({ type: userConstants.LOGIN_FAILURE, error });

    return dispatch => {

		dispatch(request({ email }));
		const { location } = history;
		location.state && typeof window !== 'undefined' && localStorage.setItem("prevPath", getFullPathFromPrivateRoute(location));
        userService.login(email, password)
            .then(
                user => {
					dispatch(success(user));
					setCentralAuthCookies(user.token, "vecindario")
					redirectAfterLogin(user, fromRegister);
                },
                error => {
                    dispatch(failure(error.toString()));
                    // dispatch(alertActions.error(error.toString()));
                }
            );
    };
}

export const logout = () => {
	history.push(loginRoute);
    userService.logout();
    return { type: userConstants.LOGOUT };
}

export const register = (user) => {

	const request = (user) =>  ({ type: userConstants.REGISTER_REQUEST, user });
	const success = (user) =>  ({ type: userConstants.REGISTER_SUCCESS, user });
	const failure = (error) =>  ({ type: userConstants.REGISTER_FAILURE, error });
    return dispatch => {

		dispatch(request(user));
		// dispatch(alertActions.clear());

        userService.register(user)
            .then(
                () => {
					dispatch(success(user));
					//dispatch(alertActions.success(SIGN_UP_SUCCESSFUL));
                },
                error => {
                    dispatch(failure(error.toString()));
                    // if(!error.toString().includes("ya se encuentra registrado")) dispatch(alertActions.error(error.toString()));
                }
            );
    };
}

export const resetPassword = (email) => {
	const resetPasswordRequest = () => ({type: userConstants.RESET_PASSWORD_REQUEST});
	const resetPasswordSuccess = () => ({type: userConstants.RESET_PASSWORD_SUCCESS});
	const resetPasswordFailure = error => ({type: userConstants.RESET_PASSWORD_FAILURE, error});;

	return dispatch => {
		dispatch(resetPasswordRequest());

		userService.resetPassword(email)
		.then(response => {
			dispatch(resetPasswordSuccess());
			// history.push(loginRoute);
			// dispatch(alertActions.success(RECOVERY_PASSWORD_LINK_SENDED, RECOVERY_PASSWORD_LINK_SENDED_TITLE));
		})
		.catch((error) =>{
			dispatch(resetPasswordFailure(error));
		})
	}
}

export const updatePassword = (resetToken,newPassword,newPasswordConfirmation) => {
	const updatePasswordRequest = () => ({type: userConstants.UPDATE_PASSWORD_REQUEST});
	const updatePasswordSuccess = () => ({type: userConstants.UPDATE_PASSWORD_SUCCESS});
	const updatePasswordFailure = (error) => ({type: userConstants.UPDATE_PASSWORD_FAILURE, error});
	return dispatch => {
		dispatch(updatePasswordRequest());
		// dispatch(alertActions.clear());

		userService.updatePassword(resetToken,newPassword, newPasswordConfirmation)
		.then(() => {
			dispatch(updatePasswordSuccess());
			history.push(loginRoute)
			// dispatch(alertActions.success(PASSWORD_UPDATED_MESSAGE));
		})
		.catch(error => {
			dispatch(updatePasswordFailure(error.toString()));
			// dispatch(alertActions.error(error.toString()));
		})
	}
}

export const clearResetPasswordFields = () => ({type: userConstants.CLEAR_RESET_PASSWORD_FIELDS});

export const userActions = {
	login,
	logout,
	register,
	resetPassword,
	updatePassword,
	updateUser,
	clearResetPasswordFields
};