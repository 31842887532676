export const homeRoute = "/";

// AUTH
export const loginRoute = "/ingresar";
export const registerRoute = "/registrarse";
export const resetPassword = "/olvidaste-tu-contrasena";
export const updatePassword = "/restablecer-contrasena";
export const acceptProjectInvitationRoute = "/aceptar-invitacion-empresa";

// ADMIN
const NAMESPACE = "empresas";
export const employeesRoute = (slug = ":slug") => `/${NAMESPACE}/${slug}/empleados`;
export const employeesPositiveResultRoute = (slug = ":slug") => `/${NAMESPACE}/${slug}/empleados/resultado/positivo`;
export const employeesNegativeResultRoute = (slug = ":slug") => `/${NAMESPACE}/${slug}/empleados/resultado/negativo`;
export const corporateRoute = (slug = ":slug") => `/${NAMESPACE}/${slug}`;
export const createCorporateRoute = `/${NAMESPACE}`;
export const editCorporateRoute = (slug = ":slug") => `/${NAMESPACE}/${slug}/editar`;
export const createLandingRoute = (slug = ":slug") => `/${NAMESPACE}/${slug}/creador`;
export const userListRoute = (slug = ":slug") => `/${NAMESPACE}/${slug}/usuarios`;
export const userEditProfileRoute = '/perfil/editar';
export const userProfileRoute = '/perfil';
export const welcomeLandingRoute = (slug = ":slug") => `/${NAMESPACE}/${slug}/landing`;
export const landingCustomizerRoute = (slug = ":slug") => `/${NAMESPACE}/${slug}/visor`;