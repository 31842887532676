import React, { useEffect } from 'react';
import OnlyDesktop from './components/OnlyDesktop';
import LoadingPage from './components/Utils/LoadingPage';
import { checkCookieExistence, mobileCheck, redirectAfterLogin } from './helpers/common-functions';
import Router from './Router';
import { getUserFromCentralToken } from './services/user.service';
import Toast from './components/Utils/Toast/Toast';
import { getCorporationsByUser } from './actions/application.actions';
import './styles/general.scss';
import { userActions } from './actions/user.actions';
import { connect } from 'react-redux';

const currentUser = typeof window !== 'undefined' && JSON.parse(localStorage.getItem('user'));

const App = ({ getCorporationsByUser, updateUser, loadingGetCorporations, alert }) => {

	useEffect(() => {
		if (currentUser && currentUser.token) {
			getCorporationsByUser();
		}
	}, [getCorporationsByUser])

	useEffect(() => {
		if (!currentUser) {
			const creatorApp = checkCookieExistence("creatorApp");
			const jwtCentral = checkCookieExistence("centralAuth");
			if (creatorApp && jwtCentral) {
				getUserFromCentralToken(creatorApp, jwtCentral, "vecindario").then(user => {
					updateUser(user);
					redirectAfterLogin(user, false);
				});
			}
		}
	}, [updateUser]);

	return (
		currentUser && loadingGetCorporations ?
		<LoadingPage /> :
		<>
			{
				alert.message && <Toast {...alert} />
			}
			{
				mobileCheck() ?
					<OnlyDesktop /> :
					<Router />
			}
		</>
	);
}

const mapStateToProps = ({ alert, applicationReducer: { corporationsRequested, loadingGetCorporations } }) => ({ alert, corporationsRequested, loadingGetCorporations });

const actionCreators = {
	getCorporationsByUser,
	updateUser: userActions.updateUser
}

export default connect(mapStateToProps, actionCreators)(App);
