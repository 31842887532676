import { urlEditCorporation, urlGetCorporations, urlGetOneCorporation, urlGetShowApprovalConditions, urlSubmitForApproval, urlSubmitPublishSite } from '../constants/urls';
import { authHeader } from '../helpers/auth-header';
import { handleResponse } from '../helpers/common-functions';

export const getCorporationsByCurrentUser = () => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(urlGetCorporations, requestOptions).then(handleResponse);
}

export const getOneCorporation = (slug) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(urlGetOneCorporation(slug), requestOptions).then(handleResponse);
}

export const postCreateCorporation = (data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data)
	};

	return fetch(urlGetCorporations, requestOptions).then(handleResponse);
}

export const patchEditCorporation = (slug, data) => {
	const requestOptions = {
		method: 'PATCH',
		headers: authHeader(),
		body: JSON.stringify(data)
	};

	return fetch(urlEditCorporation(slug), requestOptions).then(handleResponse);
}

export const submitForApproval = (slug) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader()
	};

	return fetch(urlSubmitForApproval(slug), requestOptions).then(handleResponse);
}

export const getShowApprovalConditionsService = (slug) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(urlGetShowApprovalConditions(slug), requestOptions).then(handleResponse);
}

export const submitPublishSite = (slug) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader()
	};

	return fetch(urlSubmitPublishSite(slug), requestOptions).then(handleResponse);
}