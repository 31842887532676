import { applicationConstants } from '../constants/application.constants';

const initialState = {
	corporations: [],
	currentCorporation: null,
	corporationsRequested: false,
	loadingGetCorporations: false,
	path: "/",
	rolInCurrentCorporation: null,
	corporationRolRequested: false,
	lastSave: null,
	autoSaving: false,
	hasActiveEmployees: false,
	haveBeenPublished: false
}

export const applicationReducer = (state = initialState, action) => {
	const { corporation, currentCorporation, corporations, error, type, corporationToCreate, path, viewPort, approvalConditions } = action;

	switch (type) {
		case applicationConstants.SET_CURRENT_CORPORATION:
			return { ...state, currentCorporation: currentCorporation };
		case applicationConstants.GET_CORPORATIONS_REQUEST:
			return { ...state, loadingGetCorporations: true, corporationsRequested: true };
		case applicationConstants.GET_CORPORATIONS_SUCCESS:
			return { ...state, loadingGetCorporations: false, corporations: corporations || [], corporationsRequested: true };
		case applicationConstants.GET_CORPORATIONS_FAILURE:
			return { ...state, loadingGetCorporations: false, error, corporationsRequested: true };
		case applicationConstants.ADD_CORPORATION:
			const { corporations: stateCorporations } = state;
			const alreadyExist = stateCorporations.find(oldCorporation => oldCorporation.id === corporation.id);
			let newCorporations
			if (alreadyExist) {
				newCorporations = stateCorporations.map(oldCorporation => oldCorporation.id === corporation.id ? corporation : oldCorporation);
			} else {
				newCorporations = [...stateCorporations, corporation]
			}
			return { ...state, corporations: newCorporations };
		case applicationConstants.SET_CORPORATION_TO_CREATE:
			return { ...state, corporationToCreate: corporationToCreate }
		case applicationConstants.SET_CURRENT_PATH:
			return { ...state, path }
		case applicationConstants.GET_CORPORATION_ROL_SUCCESS:
			return {
				...state,
				rolInCurrentCorporation: action.rol,
				corporationRolRequested: true
			}
		case applicationConstants.GET_CORPORATION_ROL_FAILURE:
			return {
				...state,
				rolInCurrentCorporation: null,
				corporationRolRequested: true
			}
		case applicationConstants.SET_VIEW_PORT:
			return { ...state, viewPort }
		case applicationConstants.SET_AUTO_SAVING:
			return { ...state, autoSaving: true }
		case applicationConstants.SET_LAST_AUTOSAVE:
			// TODO: Move new Date() to action, reducer must be a pure function
			return { ...state, lastSave: new Date(), autoSaving: false }
		case applicationConstants.GET_SHOW_APPROVAL_REQUEST:
			return { ...state, loadingApprovalConditions: true }
		case applicationConstants.GET_SHOW_APPROVAL_SUCCESS:
			return { ...state, loadingApprovalConditions: false, ...approvalConditions }
		case applicationConstants.GET_SHOW_APPROVAL_FAIL:
			return { ...state, loadingApprovalConditions: false, loadingApprovalError: error }
		default:
			return state;
	}
}