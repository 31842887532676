import { customColorsConstants } from "../constants/customColors.constants";

const initialState = {
	titleBannerColor: { r: 255, g: 255, b: 255, a: 1 },
	textBannerColor: { r: 255, g: 255, b: 255, a: 1 },
	subtitleColor: { r: 51, g: 51, b: 51, a: 1 },
	textColor: { r: 51, g: 51, b: 51, a: 1 },
	buttonTextColor: { r: 51, g: 51, b: 51, a: 1 },
	buttonBgColor: { r: 253, g: 220, b: 5, a: 1 },
}

export const customColorsReducer = (state = initialState, { type, payload: { field, color, colors } = {} }) => {
	switch (type) {
		case customColorsConstants.SET_COLOR:
			const newState = { ...state };
			newState[field] = color
			return newState;
		case customColorsConstants.SET_ALL_COLOR:
			return {
				...state,
				...colors
			}
		default:
			return state
	}
}