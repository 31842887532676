import { lazy } from 'react';
import * as routes from './routes';
import { UnauthenticatedRoute } from '../helpers/RedirectRoute';
import { Route } from 'react-router-dom';

const LoginContainer = lazy(() => import('./../containers/Auth/LoginContainer'));
const PasswordUpdateContainer = lazy(() => import('./../containers/Auth/PasswordUpdateContainer'));
const PasswordResetContainer = lazy(() => import('./../containers/Auth/PasswordResetContainer'));
const RegisterContainer = lazy(() => import('./../containers/Auth/RegisterContainer'));
const ActivateAccountContainer = lazy(() => import('./../containers/Auth/ActivateAccountContainer'));

export const authRouter = [
	{
		path: routes.loginRoute,
		component: LoginContainer,
		routeComponent: UnauthenticatedRoute
	},
	{
		path: routes.updatePassword,
		component: PasswordUpdateContainer,
		routeComponent: UnauthenticatedRoute
	},
	{
		path: routes.registerRoute,
		component: RegisterContainer,
		routeComponent: UnauthenticatedRoute
	},
	{
		path: routes.acceptProjectInvitationRoute,
		component: ActivateAccountContainer,
		routeComponent: Route
	},
	{
		path: routes.resetPassword,
		component: PasswordResetContainer,
		routeComponent: UnauthenticatedRoute
	}
]