export const userConstants = {

    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
	DELETE_FAILURE: 'USERS_DELETE_FAILURE',

	UPDATE_USER: 'UPDATE_USER',

	RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
	RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
	RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
	CLEAR_RESET_PASSWORD_FIELDS: 'CLEAR_RESET_PASSWORD_FIELDS',

	UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_REQUEST',
	UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
	UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',
};

export const CALENDAR_DAYS = [
	{
		display: "L",
		key: "monday"
	},
	{
		display: "M",
		key: "tuesday"
	},
	{
		display: "X",
		key: "wednesday"
	},
	{
		display: "J",
		key: "thursday"
	},
	{
		display: "V",
		key: "friday"
	},
	{
		display: "S",
		key: "saturday"
	},
	{
		display: "D",
		key: "sunday"
	},
];

export const HOURS_DAY = [
	{
		display: "12:00 a.m.",
		key: "0"
	},
	{
		display: "1:00 a.m.",
		key: "1"
	},
	{
		display: "2:00 a.m.",
		key: "2"
	},
	{
		display: "3:00 a.m.",
		key: "3"
	},
	{
		display: "4:00 a.m.",
		key: "4"
	},
	{
		display: "5:00 a.m.",
		key: "5"
	},
	{
		display: "6:00 a.m.",
		key: "6"
	},
	{
		display: "7:00 a.m.",
		key: "7"
	},
	{
		display: "8:00 a.m.",
		key: "8"
	},
	{
		display: "9:00 a.m.",
		key: "9"
	},
	{
		display: "10:00 a.m.",
		key: "10"
	},
	{
		display: "11:00 a.m.",
		key: "11"
	},
	{
		display: "12:00 p.m.",
		key: "12"
	},
	{
		display: "1:00 p.m.",
		key: "13"
	},
	{
		display: "2:00 p.m.",
		key: "14"
	},
	{
		display: "3:00 p.m.",
		key: "15"
	},
	{
		display: "4:00 p.m.",
		key: "16"
	},
	{
		display: "5:00 p.m.",
		key: "17"
	},
	{
		display: "6:00 p.m.",
		key: "18"
	},
	{
		display: "7:00 p.m.",
		key: "19"
	},
	{
		display: "8:00 p.m.",
		key: "20"
	},
	{
		display: "9:00 p.m.",
		key: "21"
	},
	{
		display: "10:00 p.m.",
		key: "22"
	},
	{
		display: "11:00 p.m.",
		key: "23"
	},
]

export const ADMIN_ROLE = 'admin';
export const PROJECT_LEADER_ROLE = 'project_leader';
export const DESIGNER_ROLE = 'designer';
export const HUMAN_MANAGER_ROLE = 'human_manager';